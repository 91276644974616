import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { RadioGroupComponent } from './radio-group.component';

@NgModule({
  declarations: [
    RadioGroupComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RadioGroupComponent
  ]
})
export class RadioGroupModule {}