import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { TranslateModule } from '@ngx-translate/core';


import { PanelModule } from './../../panel/panel.module';
import { WizardComponent } from './wizard.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    WizardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    PanelModule,
    TranslateModule.forChild(),
  ],
  exports: [
    WizardComponent
  ]
})
export class WizardModule {}