import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { SubmissionStepIntroComponent } from './submission-step-intro.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    SubmissionStepIntroComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,    
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SubmissionStepIntroComponent
  ]
})
export class SubmissionStepIntroModule {}