import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConnectionService } from '../connection/connection.service';

declare var dataLayer: any;
declare global {
  interface Window {
    dataLayer: any;
    FirebasePlugin: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService implements OnDestroy {
  private renderer: Renderer2;
  private connectionObserver: any;
  public _loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loaded$: Observable <boolean> = this._loaded.asObservable();

  public get loaded(): Boolean {
    return this._loaded.getValue();
  }

  constructor(
    private connectionProvider: ConnectionService,
    private rendererFactory: RendererFactory2,
    private platform: Platform,
    @Inject(DOCUMENT) private _document) {

    if (!this.platform.is('cordova')) {
      // Hack: https://github.com/angular/angular/issues/17824
      this.renderer = this.rendererFactory.createRenderer(null, null);
      this.connectionObserver = this.connectionProvider.connected$.subscribe(isConnected => {
        if (!isConnected) {
          console.warn('[Google Analytics Provider] App is offline, cannot load Google Analytics');
        } else {
          if (!this.loaded) {
            this.insertScript()
              .then(() => {
                this._loaded.next(true);
                this._loaded.complete();
                console.debug('[Google Analytics Provider] Google Analytics loaded');
              })
              .catch(err => {
                console.warn("[Google Analytics Provider] Error loading gtag.js library:", err);
              })
          } else {
            console.warn('[Google Analytics Provider] gtag.js library is already loaded');
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.connectionObserver.unsubscribe();
  }

  gtag(...args) {
    dataLayer.push(arguments);
  }

  /**
   * Send event
   * 
   */
  public send(name, category=undefined, value=undefined): void {
    try {
      if (this.connectionProvider.connected) {
        if (! this.platform.is('cordova')) {
          let options = {}
          if (category) {
            options['event_category'] = category;
          }
          if (value) {
            options['event_label'] = value;
          }
          console.debug("[Google Analytics Provider] Sending event:", name, options);
          this.gtag('event', name, options);
        } else {
          let options = {}
          if (category) {
            options['category'] = category;
          }
          if (value) {
            options['value'] = value;
          }
          console.debug("[Google Firebase Provider] Sending event:", name, options);
          window['FirebasePlugin'].logEvent(name, options);
        }
      } else {
        console.warn("[Google Analytics Provider] Couldn't send event: offline");
      }
    } catch (err) {
      console.warn("[Google Analytics Provider] Couldn't send event: unknown error", err);
    }
  }
  
  /**
  * Insert Analytics script
  * 
  * Insert the analytics script into the DOM
  */
  private insertScript(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        
        if (environment.GOOGLE_ANALYTICS_ID) {
          let script = this.renderer.createElement('script');
          script.id = 'googleAnalytics';
          script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GOOGLE_ANALYTICS_ID}`;
          script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            
            this.gtag('js', new Date());
            this.gtag('config', environment.GOOGLE_ANALYTICS_ID, { 'send_page_view': false });
            
            if (environment.GOOGLE_ANALYTICS_ID) {
              this.gtag('config', environment.GOOGLE_ANALYTICS_ID);
            } else {
              console.warn("[Google Analytics Provider] Adwords not configured");
            }

            resolve();
          }
          this.renderer.appendChild(this._document.body, script);
        } else {
          reject("Analytics ID not preset");
        }
      } catch (err) {
        reject(err);
      }
    });
  }
}
