import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { CategoryService } from 'src/app/services/category/category.service';
import { GroupService } from 'src/app/services/group/group.service';
import { WizardStepComponent } from '../../wizard-step/wizard-step.component';

@Component({
  selector: 'submission-step-category',
  templateUrl: './submission-step-category.component.html',
  styleUrls: ['./submission-step-category.component.scss'],
  providers: [{provide: WizardStepComponent, useExisting: forwardRef(() => SubmissionStepCategoryComponent)}]
})
export class SubmissionStepCategoryComponent extends WizardStepComponent implements OnInit, OnDestroy {

  @Input() public formData;
  public form: FormGroup;
  public groups = [];
  private groupObserver;

  // Alias the isValid property so that the parent wizard can check when step is complete
  get isValid() {
    return this.form['valid'];
  }

  constructor(
    private fb: FormBuilder,
    public categoryProvider: CategoryService,
    public groupProvider: GroupService
  ) { 
    super();
  }

  ngOnInit() {
    // Setup form
    this.form = this.fb.group({
      categories: [new FormArray([]), [Validators.required]],
      groups: new FormArray([])
    });

    // Insanely, this seems like how we should handle checkboxes ...
    //
    // We have to dynamically add a new control to the form build for every 
    // group checkbox we want to show in the form. Because this subscriptions
    // might fire a number of times, we need to first clear the form array
    //
    // https://netbasal.com/handling-multiple-checkboxes-in-angular-forms-57eb8e846d21
    // https://coryrylan.com/blog/creating-a-dynamic-checkbox-list-in-angular
    this.groupObserver = this.groupProvider.groups$.subscribe(groups => {
      if (groups.length > 0) {
        this.groups = groups;

        let groupsFormArray = this.form.get('groups') as FormArray;

        while (groupsFormArray.length !== 0) {
          groupsFormArray.removeAt(0)
        }
  
        groups.forEach(group => {
          groupsFormArray.push(new FormControl(false));
        });
      }
    });

    // Write changes to data model
    this.form.valueChanges.subscribe(form => {
      this.formData.categories = form.categories;

      // There must be a better way to do this. To
      // get an array of ids for the chosen groups we
      // need to conver back from the forms array
      // of "checked" values
      let ids = [];
      form.groups.forEach((checked, i) => {
        if (checked) {
          ids.push(this.groups[i]['id']);
        }
      });

      this.formData.groups = ids;
    });
  }

  ngOnDestroy() {
    this.groupObserver.unsubscribe();
  }
}
