import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

export interface Option {
  text: string,
  value: string,
  checked: boolean
}

@Component({
  selector: 'radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements OnInit {

  @Input() options: Option[] = [];
  @Input() theme: string;
  @Output() onChange = new EventEmitter();

  constructor(public element: ElementRef, public renderer: Renderer2) { 

  }

  ngOnInit() {}
}
