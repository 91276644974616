import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { SubmissionStepCategoryComponent } from './submission-step-category.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SubmissionStepCategoryComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,   
    FormsModule,
    ReactiveFormsModule, 
    TranslateModule.forChild(),
  ],
  exports: [
    SubmissionStepCategoryComponent
  ]
})
export class SubmissionStepCategoryModule {}