import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { modalAnimation } from 'src/app/utils/animations';

@Component({
  selector: 'download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss'],
  host: {
    '[@modalAnimation]': ''
  },
  animations: [
    modalAnimation
  ]
})
export class DownloadModalComponent {

  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(public platform: Platform) {
  }

  buttonClicked() {
    if (this.platform.is('android')) {
      window.open('https://play.google.com/store/apps/details?id=com.therefugeecenter.findhello');
    }
    else if (this.platform.is('ios')) {
      window.open('https://itunes.apple.com/gb/app/findhello-immigrant-help/id1446325524');
    }
    this.close.emit();
  }

}
