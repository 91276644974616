import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private labels: string[] = ["English", "(Arabic) العربية", "Español (Spanish)", "Українська (Ukrainian)","(Dari) دری","(Pashto) پښتو"];
  public languageLimit: number = 6;
  private validCodes: string[] = ['en', 'ar', 'es','uk','fa',"ps"];
  private storageKey: string = 'languageCode';
  private defaultCode: string = 'en';
  public textDirection: string = 'ltd';
  public currentLanguage: string = 'en';

  constructor(
    private storageProvider: Storage,
    private translateProvider: TranslateService) {
    this.translateProvider.onLangChange.subscribe((event) => {
      this.textDirection = (event.lang === 'ar') ? 'rtl' : 'lrt';
    });
  }

  /**
   * Language options
   * 
   */
  public options$ = new Observable<any[]>(observer => {
    let options = [], text, value;
    for (let i = 0; i < this.labels.length; i++) {
      value = this.validCodes[i];
      text = this.labels[i];
      options.push({
        text,
        value,
        // Check previously selected language, otherwise default language
        checked: ( (this.currentLanguage && value === this.currentLanguage) || (value === this.defaultCode) )
      })
    }
    observer.next(options);
    observer.complete();
  });

  /**
   * Get total of language options
   */
  public totalLanguages(): number {
    return this.validCodes.length;
  }

  /**
   * Get language code
   * 
   * Get the current language code from the underlying storage if possible,
   * if not set a default and save it to storage for next time
   */
  public getCode(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.currentLanguage) {
        resolve(this.currentLanguage);
      } else {
        this.storageProvider.get(this.storageKey).then(code => {
          if (code === null) {
            code = this.defaultCode;
            this.storageProvider.set(this.storageKey, code);
          }
          this.currentLanguage = code;
          resolve(code);
        }).catch(error => reject(error));
      }
    });
  }

  /**
   * Set language code
   * 
   * @param code 
   */
  public setCode(code): Promise<any> {
    
    return new Promise((resolve, reject) => {
      if (this.validCodes.indexOf(code) === -1) {
        reject(`"${code}" is not a valid language code`)
      } else {
        
        console.debug(`[Language provider] Setting language "${code}"`);
        this.currentLanguage = code;

        this.translateProvider.use(code);
        this.storageProvider.set(this.storageKey, code)
          .then(resolve)
          .catch(reject);
      }
    });
  }
}
