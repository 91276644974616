import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { HTTP } from '@ionic-native/http/ngx';

import { ApiService } from '../api/api.service';
import { LanguageService } from '../language/language.service';
import { ConnectionService } from '../connection/connection.service';
import { HttpClient } from '@angular/common/http';

export interface Category {
  id: number,
  name: string,
  isActive: boolean
}

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends ApiService{

  constructor(
    public http: HttpClient,
    private storageProvider: Storage,
    public languageProvider: LanguageService,
    public connectionProvider: ConnectionService) {

    super(http, languageProvider)
  }

  private _categories: BehaviorSubject<Category[]> = new BehaviorSubject(<Category[]>[]);
  public categories$: Observable<Category[]> = this._categories.asObservable();
  public get categories() {
    return this._categories.getValue();
  }

  private _activeCategory: BehaviorSubject<Category> = new BehaviorSubject(undefined);
  public activeCategory$: Observable<Category> = this._activeCategory.asObservable();
  public get activeCategory() {
    return this._activeCategory.getValue();
  }

  /**
   * Update categories
   * 
   * Update the categories from either the API (if online) or storage if 
   * there's anything there
   */
  update() {
    if (this.connectionProvider.connected) {
      let url = `${this.apiURL()}categories`;
      this.http
        .get<any[]>(url)
        .subscribe(
          categories => {
            categories.forEach(category => category['isActive'] = false);
            this.storageProvider
              .set('latestCategories', categories)
              .then(() => {
                console.debug('[Category provider] Categories persisted to storage:', categories);
              })
              .catch(err => {
                console.error('[Category provider] Error persisting categories to storage:', err);
              })
            this._categories.next(categories);
            this._activeCategory.next(undefined);
          },
          error => {
            console.error(error);
          });
    } else {
      this.storageProvider.get('latestCategories').then(categories => {
        console.debug(`[Category provider] Offline, fetched categories from storage:`, categories);
        this._categories.next(categories);
        this._activeCategory.next(undefined);
      });
    }
  }

  /**
   * Select
   * 
   * Select a single category as "active"
   */
  selectCategory(category: Category) {
    console.debug('[Category provider] Selecting category:', category['id']);
    this.categories.forEach(category => category['isActive'] = false);
    let activeCategory = this.categories.find(category_ => (category_['id'] === category['id']));
    if (activeCategory) {
      activeCategory['isActive'] = true;
      this._activeCategory.next(activeCategory);
    }
  }

  /**
   * Deselect
   * 
   * Deselect any "active" category
   */
  deselectCategory() {
    console.debug('[Category provider] Deselecting all categories');
    this.categories.forEach(category => category['isActive'] = false);
    this._activeCategory.next(undefined);
  }
}
