import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConnectionService } from '../connection/connection.service';
import { LocationService } from '../location/location.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapSdkService implements OnDestroy {

  public DEFAULT_ZOOM = 12;
  public DEFAULT_LATITUDE = 45.527678;
  public DEFAULT_LONGITUDE = -122.640627;
  
  private renderer: Renderer2;
  private connectionObserver;
  
  public _loaded: BehaviorSubject <boolean> = new BehaviorSubject(false);
  public loaded$: Observable<boolean> = this._loaded.asObservable();
  public get loaded(): Boolean {
    return this._loaded.getValue();
  }
  
  private autocompleteService;
  private geocoderService;
  private placesService;

  constructor(
    private connectionProvider: ConnectionService,
    private rendererFactory: RendererFactory2,
    private locationProvider: LocationService,
    @Inject(DOCUMENT) private _document
  ) { 
    // Hack: https://github.com/angular/angular/issues/17824
    this.renderer = this.rendererFactory.createRenderer(null, null);
    
    this.connectionObserver = this.connectionProvider.connected$.subscribe(isConnected => {
      if (! isConnected) {
        console.warn('App is offline, cannot load Google Maps SDK');
      } else {
        if (! this.loaded) {
          this.insertMapsSDK()
            .then(() => {
              return this.insertMarkerSDKAddon();
            })
            .then(() => {
              console.debug('[Google Maps SDK Provider] SDK Loaded');
              this._loaded.next(true);
              this._loaded.complete();
              
              this.autocompleteService = new google.maps.places.AutocompleteService();
              this.geocoderService = new google.maps.Geocoder;
              this.placesService = new google.maps.places.PlacesService(
                new google.maps.Map(document.createElement('div'))
              );
            })
            .catch(err => {
              console.warn("[Google Maps SDK Provider] Error loading SDK:");
              console.warn(err);
            })
        } else {
          console.warn('[Google Maps SDK Provider] SDK is already loaded');
        }
      }
    });
  }

  ngOnDestroy() {
    this.connectionObserver.unsubscribe();
  }
  
  /**
  * Insert maps SDK
  * 
  * Insert the maps SDK into the DOM
  */
  private insertMapsSDK(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let script = this.renderer.createElement('script');
        script.id = 'googleMaps';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAP_API}&libraries=places`;
        script.onload = () => {
          resolve();
        }
        this.renderer.appendChild(this._document.body, script);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Insert the marker label addon library
   * 
   * Insert the marker label addon library into the DOM
   */
  private insertMarkerSDKAddon(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let script = this.renderer.createElement('script');
        script.id = 'markerLabels';
        script.src = 'assets/js/libs/markerwithlabel_packed.js';
        script.onload = () => {
          resolve();
        }
        this.renderer.appendChild(this._document.body, script);
      } catch (err) {
        reject(err);
      }
    });
  }
  
  /**
  * Google Place predeictions
  * 
  * Get address suggestions from a query string
  * 
  * Notes:
  * - https://developers.google.com/places/supported_types#table3
  * - https://developers.google.com/maps/documentation/javascript/reference/3/places-widget#AutocompletionRequest
  */
  getPlacePredictions(query, types=['geocode']): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (! this.loaded || ! this.connectionProvider.connected) {
        reject('Offline/Google Maps SDK is not loaded');
      } else {
        if (!query || query === '') {
          resolve([]);
        } else {
          let params = {
            input: query,
            componentRestrictions: {
              country: 'us'
            },
            types
          }
          
          // Insert the user's location for better results
          const position = this.locationProvider.position;
          if (typeof position !== 'undefined') {
            params['location'] = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            params['radius'] = 32186; // 20 miles: 20 x 1609.34
          }

          this.autocompleteService.getPlacePredictions(params, (results) => {
            resolve(results);
          });
        }
      }
    });
  }
  
  /**
  * Get single place detail
  * 
  * Get information (primarily the lat/lng) of a particular place
  */
  getPlaceDetails(placeId): Promise<{}> {
    return new Promise((resolve, reject) => {
      if (! this.loaded || ! this.connectionProvider.connected) {
        reject('Offline/Google SDK is not loaded');
      } else {
        this.placesService.getDetails({
          placeId,
          //fields: ['geometry']
        }, (result) => {
          resolve(result);
        });
      }
    });
  }
  
  /**
  * Parse details
  * 
  * Parse the result of a `getDetails` call to pull out the information
  * that is relevant to our addres model
  */
  parsePlaceDetailAddress(result): Object {
    let address, city, state, zip;
    
    function findType(result, label) {
      try {
        return result['address_components'].find(component => {
          return (component['types'].indexOf(label) !== -1);
        })['short_name'];
      } catch (err) {
        console.warn(`[Google Maps SDK Provider] Couldn't parse ${label} from Google Places result`);
      }
      return undefined;
    }
    
    city = findType(result, 'locality');
    state = findType(result, 'administrative_area_level_1');
    zip = findType(result, 'postal_code');
    
    try {
      address = result['formatted_address'].split(',')[0];
    } catch (err) {
      console.warn("[Google Maps SDK Provider] Couldn't parse main address from Google Places result");
    }
    
    return {
      address,
      city,
      state,
      zip
    }
  }

  /**
   * Partition place predictions
   * 
   * 
   */
  // partitionPlacePredictions(results, types): Promise<any[]> {
  //   return new Promise((resolve, reject) => {
  //     if (types.length <= 1) {
  //       resolve(results);
  //     } else {
  //       let partionedResults = types.map(() => []);
  //       for (let i = 0; i < results.length; i++) {
  //         for (let j = 0; j < types.length; j++) {
  //           if (results[i].types.includes(types[j])) {
  //             partionedResults[j].push(results[i]);
  //             break;
  //           }
  //         }
  //       }
  //       resolve(partionedResults);
  //     }
  //   });
  // }
}
