import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WizardStepComponent } from '../../wizard-step/wizard-step.component';

@Component({
  selector: 'submission-step-contact',
  templateUrl: './submission-step-contact.component.html',
  styleUrls: ['./submission-step-contact.component.scss'],
  providers: [{provide: WizardStepComponent, useExisting: forwardRef(() => SubmissionStepContactComponent)}]
})
export class SubmissionStepContactComponent extends WizardStepComponent implements OnInit {

  @Input() public formData;
  public form: FormGroup;

  // Alias the isValid property so that the parent wizard can check when step is complete
  get isValid() {
    return this.form['valid'];
  }
  constructor(
    private fb: FormBuilder
  ) { 
    super();
  }

  log(val) { console.debug(val); }

  ngOnInit() {
    // hours probs need a validator too
    let phoneValidator = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    let websiteValidator = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    // let timeValidator = /^[1-12]{2}:[0-5]{1}[0-9]{1}$/;
  
    // Setup form
    this.form = this.fb.group({
      website: ["", [Validators.maxLength(200), Validators.pattern(websiteValidator)]],
      phone: ["", [Validators.maxLength(14), Validators.pattern(phoneValidator)]],
      description: ["", [Validators.maxLength(500)]],
      hours:["", ],
      multipleLocations:[""]
    });

    // Write changes to data model
    this.form.valueChanges.subscribe(form => {
      this.formData.website = form.website;
      this.formData.phone = form.phone;
      this.formData.description = form.description;
      this.formData.best_time_to_contact = form.hours;
      this.formData.multiple_locations = form.multipleLocations;
    });
  }

}
