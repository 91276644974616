import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Subscription, SubscriptionLike } from 'rxjs';
import { ScreenService } from 'src/app/services/screen/screen.service';
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss'],
})
export class ScrollbarComponent implements OnInit {

  private screenObserver: Subscription;
  private perfectScrollbar: any;

  constructor(
    public element: ElementRef,
    public renderer: Renderer2,
    public screenProvider: ScreenService
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.perfectScrollbar = new PerfectScrollbar(this.element.nativeElement);

    const parentContainer = this.element.nativeElement.parentElement;

    const offsetTop = parentContainer.offsetTop;
    const offsetBottom = parseInt(window.getComputedStyle(parentContainer).marginBottom);

    const marginTop = parseInt(window.getComputedStyle(this.element.nativeElement).marginTop);
    const marginBottom = parseInt(window.getComputedStyle(this.element.nativeElement).marginBottom);

    const initialMaxHeight = parseInt(window.getComputedStyle(this.element.nativeElement).maxHeight);

    // On resize, update scrollbar height
    this.screenObserver = this.screenProvider.screenHeight$.subscribe((screenHeight) => {
      console.debug('[Scrollbar component] Screen got resized, updating scrollbar size');

      const newMaxHeight = screenHeight - offsetTop - offsetBottom - marginTop - marginBottom;
      if (newMaxHeight < initialMaxHeight) {
        this.setStyle('max-height', `${newMaxHeight}px`);
      } else {
        this.setStyle('max-height', `${initialMaxHeight}px`);
      }

      this.perfectScrollbar.update();
    });
  }

  ngOnDestroy() {
    this.screenObserver.unsubscribe();
    this.perfectScrollbar.destroy();
    this.perfectScrollbar = null; // to make sure garbages are collected
  }

  private setStyle(property: string, value: any) {
    this.renderer.setStyle(this.element.nativeElement, property, value);
  }

}
