import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { WizardStepComponent } from '../../wizard-step/wizard-step.component';

@Component({
  selector: 'submission-step-intro',
  templateUrl: './submission-step-intro.component.html',
  styleUrls: ['./submission-step-intro.component.scss'],
  providers: [{provide: WizardStepComponent, useExisting: forwardRef(() => SubmissionStepIntroComponent)}]
})
export class SubmissionStepIntroComponent extends WizardStepComponent {

  @Output() start: EventEmitter<WizardStepComponent> = new EventEmitter<WizardStepComponent>();
  constructor() {
    super();

    this.showProgress = false;
    this.isValid = true;
    this.showFooter = false;
   }

  ngOnInit() {}

}
