import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Option } from '../../components/radio-group/radio-group.component';
import { ApiService } from '../api/api.service';
import { ConnectionService } from '../connection/connection.service';
import { LanguageService } from '../language/language.service';
import { map } from 'rxjs/operators';

export interface LanguageFilter {
  id: number,
  name: string
}

export interface LanguageFilterOption extends Option {
  isVisible: boolean
}

@Injectable({
  providedIn: 'root'
})
export class LanguageFilterService extends ApiService {

  constructor(
    public http: HttpClient,
    private storageProvider: Storage,
    public languageFilterProvider: LanguageService,
    private translate: TranslateService,
    public connectionProvider: ConnectionService
  ) { 
    super(http, languageFilterProvider)
  }

  private _languageFilters: BehaviorSubject<LanguageFilter[]> = new BehaviorSubject(<LanguageFilter[]>[]);
  public languageFilters$: Observable<LanguageFilter[]> = this._languageFilters.asObservable();
  public get languageFilters() {
    return this._languageFilters.getValue();
  }

  private _activeLanguageFilter: BehaviorSubject<LanguageFilter> = new BehaviorSubject(undefined);
  public activeLanguageFilter$: Observable<LanguageFilter> = this._activeLanguageFilter.asObservable();
  public get activeLanguageFilter() {
    return this._activeLanguageFilter.getValue();
  }

  private languageList = [{'id': 1, 'name': 'Amharic', 'is_visible': true}, 
                          {'id': 2, 'name': 'Arabic', 'is_visible': true}, 
                          {'id': 3, 'name': 'Burmese', 'is_visible': true}, 
                          {'id': 4, 'name': 'Chinese (simplified)', 'is_visible': true},
                          {'id': 5, 'name': 'Dari', 'is_visible': true}, 
                          {'id': 6, 'name': 'English', 'is_visible': true},
                          {'id': 7, 'name': 'Filipino', 'is_visible': true}, 
                          {'id': 8, 'name': 'French', 'is_visible': true},
                          {'id': 9, 'name': 'Hindi', 'is_visible': true}, 
                          {'id': 10, 'name': 'Korean', 'is_visible': true},
                          {'id': 11, 'name': 'Nepali', 'is_visible': true}, 
                          {'id': 12, 'name': 'Pashto', 'is_visible': true},
                          {'id': 13, 'name': 'Persian', 'is_visible': true}, 
                          {'id': 14, 'name': 'Portuguese', 'is_visible': true},
                          {'id': 15, 'name': 'Russian', 'is_visible': true}, 
                          {'id': 16, 'name': 'Spanish', 'is_visible': true},
                          {'id': 17, 'name': 'Swahili', 'is_visible': true}, 
                          {'id': 18, 'name': 'Tigrinya', 'is_visible': true},
                          {'id': 19, 'name': 'Ukrainian', 'is_visible': true}, 
                          {'id': 20, 'name': 'Urdu', 'is_visible': true},
                          {'id': 21, 'name': 'Vietnamese', 'is_visible': true}
                          
                          ]

  /**
   * Options
   * 
   * Return the Language Filter values as translation-friendly options fit for a
   * radio group
   */
  public languageFilterOptions$: Observable<LanguageFilterOption[]> = new Observable(observer => {
    let translationObservable;
    let languageFilterObservable = combineLatest(
      this.languageFilters$,
      this.activeLanguageFilter$)
    .subscribe((values) => {
      let options = [];
      let [languageFilters, activeLanguageFilter] = values;

      translationObservable = this.translate.get('list.groups.modal.all').subscribe((text: string) => {

        options = languageFilters.map(languageFilter => {
          let checked = (typeof activeLanguageFilter !== 'undefined' && languageFilter['id'] === activeLanguageFilter['id']);
          return <LanguageFilterOption> {
            text: languageFilter['name'],
            value: languageFilter['id'].toString(),
            checked,
            isVisible: languageFilter['is_visible']
          }
        });

        options.unshift({
          text,
          value: undefined,
          checked: (typeof activeLanguageFilter === 'undefined'),
          isVisible: true
        });

        observer.next(options);
        observer.complete();
      });

    });

    return {unsubscribe() {
      languageFilterObservable.unsubscribe();
      if (translationObservable) {
        translationObservable.unsubscribe();
      }
    }};
  });

  // public visiblelanguageFilterOptions$ = this.languageFilterOptions$.forEach((options) =>{
  //   return options.filter(options => options.isVisible === true);
  // });

  public visibleLanguageFilterOptions$ = this.languageFilterOptions$.pipe(map(item => {
    return item.filter(temp => temp.isVisible)
  }))

  /**
   * Update
   * 
   * Retreive all language filters from the server
   */
  update() {
    this._languageFilters.next(this.languageList);
    this._activeLanguageFilter.next(undefined)
    console.debug('[Language filter provider] Languge Filters:', this._languageFilters);
  }

  /**
   * Select
   * 
   * Select a single language filters as "active"
   */
  selectLanguageFilter(languageFilter: LanguageFilter) {
    console.debug('Selecting language filter:', languageFilter['id']);
    let activeLanguageFilter = this.languageFilters.find(languageFilter_ => (languageFilter_['id'] === languageFilter['id']));
    if (activeLanguageFilter) {
      this._activeLanguageFilter.next(activeLanguageFilter);
    }
  }

  /**
   * Deselect
   * 
   * Deselect any "active" language filters
   */
  deselectLanguageFilter() {
    console.debug('Deselecting all language filters');
    this._activeLanguageFilter.next(undefined);
  }
}
