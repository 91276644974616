import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { ApiService } from '../api/api.service';
import { ConnectionService } from '../connection/connection.service';
import { LanguageService } from '../language/language.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends ApiService  {

  constructor(
    public http: HttpClient,
    public language: LanguageService,
    public connectionProvider: ConnectionService
  ) { 
    super(http, language);
  }

  search(query): Promise<any[]> {
    if (this.connectionProvider.connected) {
      return this.http.get<any[]>(`${this.apiURL()}search?q=${encodeURI(query)}`).toPromise();
    } else {
      console.debug(`[Search provider] Offline, returning empty results`);
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  }

  autocomplete(query): Promise<any[]> {
    if (this.connectionProvider.connected) {
      return this.http.get<any[]>(`${this.apiURL()}autocomplete?q=${encodeURI(query)}`).toPromise();
    } else {
      console.debug(`[Search provider] Offline, returning empty results`);
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  }
}
