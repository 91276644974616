import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { RadioGroupModule } from '../../radio-group/radio-group.module';
import { ModalModule } from '../../modal/modal.module';
import { PanelModule } from '../../panel/panel.module';
import { LanguageModalComponent } from './language-modal.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    LanguageModalComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    ModalModule,
    PanelModule,
    RadioGroupModule,
    TranslateModule.forChild(),
  ],
  exports: [
    LanguageModalComponent
  ]
})
export class LanguageModalModule {}