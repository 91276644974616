import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ModalModule } from '../../modal/modal.module';
import { PanelModule } from '../../panel/panel.module';
import { AboutModalComponent } from './about-modal.component';

@NgModule({
  declarations: [
    AboutModalComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    ModalModule,
    PanelModule,
    TranslateModule.forChild(),
  ],
  exports: [
    AboutModalComponent
  ]
})
export class AboutModalModule {}