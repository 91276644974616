import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';

import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { LanguageService } from '../language/language.service';

export interface Flag {
  reason: string,
  notes: string
}

@Injectable({
  providedIn: 'root'
})
export class FlagService extends ApiService {

  get url(): string {
    return `${this.apiURL()}flags`;
  }

  constructor(
    public http: HttpClient,
    public languageProvider: LanguageService) {

    super(http, languageProvider);
  }

  public create(data: Object): Observable<Flag>{
    return this.http.post<Flag>(this.url, data);
  }
}
