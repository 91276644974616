import { Component, EventEmitter, Host, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wizard-step',
  templateUrl: './wizard-step.component.html',
  styleUrls: ['./wizard-step.component.scss'],
})
export class WizardStepComponent {

  @Input() title: string = "";
  @Input() nextText: string = "";
  @Input() showFooter: boolean = true;
  @Input() showProgress: boolean = true;

  @Input() hidden: boolean = false;
  @Input() showNext: boolean = true;
  @Input() showPrev: boolean = true;

  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPrev: EventEmitter<any> = new EventEmitter<any>();
  @Output() onComplete: EventEmitter<any> = new EventEmitter<any>();

  private _isActive: boolean = false;
  public isDisabled: boolean = true;
 
  constructor() { }

  @Input('isActive')
  set isActive(isActive: boolean) {
    this._isActive = isActive;
    this.isDisabled = false;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  @HostBinding('hidden')
  get _hidden(): boolean {
    return ! this.isActive;
  }

  _isValid: boolean = true;
  
  get isValid() {
    return this._isValid;
  }

  @Input()
  set isValid(value) {
    this._isValid = value;
  }

}
