import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
    // loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'choose-language',
    loadChildren: () =>
      import('./pages/choose-language/choose-language.module').then(
        (m) => m.ChooseLanguagePageModule
      )
  },
  {
    path: 'detail',
    loadChildren: () =>
      import('./pages/detail/detail.module').then((m) => m.DetailPageModule)
  },
  {
    path: 'list',
    loadChildren: () =>
      import('./pages/list/list.module').then((m) => m.ListPageModule)
  },
  {
    path: 'submit-resource',
    loadChildren: () =>
      import('./pages/submit-resource/submit-resource.module').then(
        (m) => m.SubmitResourcePageModule
      )
  },
  {
    path: 'thank-you',
    loadChildren: () =>
      import('./pages/thank-you/thank-you.module').then(
        (m) => m.ThankYouPageModule
      )
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule)
  }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
