import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WizardStepComponent } from '../../wizard-step/wizard-step.component';

@Component({
  selector: 'submission-step-user',
  templateUrl: './submission-step-user.component.html',
  styleUrls: ['./submission-step-user.component.scss'],
  providers: [{provide: WizardStepComponent, useExisting: forwardRef(() => SubmissionStepUserComponent)}]
})
export class SubmissionStepUserComponent extends WizardStepComponent implements OnInit {

  public form: FormGroup;
  @Input() public formData;

  // The last step of our wizard is simply an "outro" page, without
  // any form, so we need to "submit" the wizard 1 page early. To do
  // so we just use a custom submit button and event  on this wizard
  // step instead
  @Output() submit: EventEmitter<void> = new EventEmitter<void>();

  // Alias the isValid property so that the parent wizard can check when step is complete
  get isValid() {
    return this.form['valid'];
  }

  constructor(
    private fb: FormBuilder
  ) { 
    super();
    this.showFooter = false;
  }

  ngOnInit() {
    let emailValidator = "[a-zA-Z0-9._-]+[@]+[a-zA-Z0-9-]+[.]+[a-zA-Z]{2,6}";
  
    // Setup form
    this.form = this.fb.group({
      firstName: ["", [Validators.maxLength(50)]],
      lastName: ["", [Validators.maxLength(50)]],
      // Email validator is broken in Angular 5.X (email string can't be empty)
      // so we need work around:
      // https://github.com/angular/angular/issues/16183
      email: ["", [Validators.pattern(emailValidator), Validators.maxLength(100)]]
    });

    // Write changes to data model
    this.form.valueChanges.subscribe(form => {
      this.formData.submitter_first_name = form.firstName;
      this.formData.submitter_last_name = form.lastName;
      this.formData.submitter_email = form.email;
    });
  }

}
