import { Injectable, OnDestroy } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApiService } from '../api/api.service';

import { Storage } from '@ionic/storage';
import { LanguageService } from '../language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends ApiService implements OnDestroy {

  private _position: BehaviorSubject <Position> = new BehaviorSubject(undefined);
  public position$: Observable <Position> = this._position.asObservable();
  public get position(): Position {
    return this._position.getValue();
  }
  
  private onResumeSubscription: Subscription;

  constructor(
    private platform: Platform,
    private geolocation: Geolocation,
    private storage: Storage,
    public http: HttpClient,
    public language: LanguageService,
    private translate: TranslateService,
    private diagnostic: Diagnostic,
    private locationAccuracy: LocationAccuracy

  ) { 
    super(http, language);

    // Load the last known location from storage
    this.loadPosition()
    .then(position => {
      console.debug('[Location provider] Loaded saved position from storage: ', position);
      this._position.next(position);
    })
    .catch(error => {
      console.warn("[Location provider] Error loading saved position from storage:", error);
    });
    
    this.platform.ready().then(() => {
      this.onReady();
    }).catch(err => console.error(err));
  }

  ngOnDestroy() {
    this.onResumeSubscription.unsubscribe();
  }
  
  /**
  * On ready event handler
  * 
  * Conditionally retrieve the user's location when the application initially
  * loads
  */
  private onReady() {
    this.getLocation(true)
      .then(position => {
        console.debug("[Location provider] Successfully loaded initial device position:", position);
      })
      .catch(error => {
        console.warn('[Location provider] Error loading initial device position:', error);
      });
  }
  
  /**
   * Load stored position
   * 
   * Get the last known position from storage 
   */
  private loadPosition(): Promise<Position> {
    return new Promise((resolve, reject) => {
      this.storage.get('position')
        .then((position) => {
          if (! position || ! ('coords' in position)) {
            console.warn("[Location provider] No location saved in storage");
            position = undefined;  // Is returned as null otherwise
          }
          resolve(position);
        })
        .catch(reject)
    });
  }


  /**
   * Save position
   * 
   * The underlying storage doesn't serialize anything it saves so we 
   * need to pass it a new object or it will have issue cloning it
   */
  private savePosition(position): Promise<any> {
    let copy;
    
    try {
      copy = <Position> {
        coords: <Coordinates> {
          latitude: position['coords']['latitude'],
          longitude: position['coords']['longitude'],
        }
      }
    } catch (err) {
      console.error('[Location provider] Error copying new position:', err);
      copy = undefined;
    }

    this._position.next(position);

    return this.storage.set('position', copy)
  }

  /**
   * 
   * @param error 
   */
  private parseError(error): Promise<string> {
    return new Promise((resolve, reject) => {
      let key = "default";
      if ('code' in error) {
        key = error['code'];
      }
      this.translate
      .get(`global.location.errors.${key}`)
      .subscribe(translation => {
        resolve(translation);
      });
    })
  }


  /**
   * Authorize device
   * 
   * Check if permission for location services is granted, otherwise display a
   * prompt requesting the permission
   */
  private authorize(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.diagnostic
        .isLocationAuthorized()
        .then(isAuthorized => {
          if (!isAuthorized) {
            console.warn("[Location provider] Location permissions have not been accepted, prompting user");
            this.diagnostic
              .requestLocationAuthorization('WHEN_IN_USE')
              .then(status => {
                if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
                  console.debug("[Location provider] Location permissions accepted");
                  resolve(true);
                } else {
                  console.warn("[Location provider] Location permissions denied");
                  resolve(false)
                }
              })
              .catch(reject);
          } else {
            console.debug("[Location provider] Location permissions have already been accepted");
            resolve(true);
          }
        })
        .catch(reject);
    });
  }


  /**
   * Enable location on device
   * 
   * Check if location services are enabled on the device, otherwise display
   * a prompt to enable them
   */
  private enable(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.diagnostic
        .isLocationEnabled()
        .then(isEnabled => {
          if (!isEnabled) {
            console.warn("[Location provider] Location services are not enabled, prompting user");
            // Check if the device has location services
            this.locationAccuracy
              .canRequest()
              .then((canRequest: boolean) => {
                if(canRequest) {
                  // Prompt user to enable accuracy
                  this.locationAccuracy
                  .request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
                  .then((resp) => {
                    resolve();
                  }, (resp) => {
                    reject();
                  });
                } else {
                  reject();
                }
              })
              .catch(reject);
          } else {
            console.debug("[Location provider] Location services are enabled");
            resolve();
          }
        })
        .catch(reject);
    });
  }


  /**
   * Get device location
   * 
   * Wrapper for the Cordova device API location method
   */
  private getDeviceLocation(prompt = false): Promise<Geoposition> {
    let opts = {
      enableHighAccuracy: true,
      timeout: 30000, // 30 seconds
      maximumAge: 10000 // Use cached position from up to 10 seconds ago
    }

    if (prompt) {
      return this.enable()
        .then(() => this.authorize())
        .then(() => this.geolocation.getCurrentPosition(opts));
    } else {
      return this.geolocation.getCurrentPosition(opts);
    }
  }


  /**
   * Get browser location
   * 
   * Wrapper for the HTML5 browser API location method
   */
  private getBrowserLocation(): Promise<Position> {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          {
            enableHighAccuracy: true,
            timeout: 30000, // 30 seconds
            maximumAge: 10000 // Use cached position from up to 10 seconds ago
          });
      } else {
        reject("Geolocation not available for this browser");
      }
    });
  }

  
  public getLocation(prompt = true): Promise<Position> {
    return new Promise((resolve, reject) => {
      const locate = this.platform.is('cordova') ? this.getDeviceLocation(prompt) : this.getBrowserLocation();
      locate
        .then(position => {
          console.debug("[Location provider] Location retreived:", position);
          this.savePosition(position);
          resolve(position);
        })
        .catch(error => {
          console.error('[Location provider] Error retrieving location:', error);
          this.parseError(error)
            .then(translation => {
              reject(translation);
            })
        });
    });
  }
}
