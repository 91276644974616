import { trigger, transition, query, animateChild, animate, style, group, keyframes } from '@angular/animations';

export const childAnimations = trigger('childAnimations', [
  transition(':enter, :leave', [
    query('@*', animateChild(), { optional: true })
  ])
]);

export const modalAnimation = trigger('modalAnimation', [
  transition(':enter', [
    group([
      query('.window', [
        style({ opacity: 0 }),
        animate('.75s', keyframes([
          style({ opacity: 0, transform: 'translate(-50%, -50%) scale(0.3, 0.3)', offset: 0 }),
          style({ opacity: 0.8, transform: 'translate(-50%, -50%) scale(0.9, 0.9)', offset: 0.2 }),
          style({ opacity: 1, transform: 'translate(-50%, -50%) scale(1.03, 1.03)', offset: 0.4 }),
          style({ opacity: 1, transform: 'translate(-50%, -50%) scale(0.97, 0.97)', offset: 0.6 }),
          style({ opacity: 1, transform: 'translate(-50%, -50%) scale(1, 1)', offset: 1 })
        ]))
      ]),
      query('.overlay', [
        style({ opacity: 0 }),
        animate('.5s', style({ opacity: 1 }))
      ])
    ])
  ]),
  transition(':leave', [
    group([
      query('.window', [
        style({ opacity: 1 }),
        animate('.5s', keyframes([
          style({ transform: 'translate(-50%, -50%) scale(1, 1)', offset: 0 }),
          style({ transform: 'translate(-50%, -50%) scale(0.97, 0.97)', offset: 0.2 }),
          style({ transform: 'translate(-50%, -50%) scale(1.03, 1.03)', offset: 0.4 }),
          style({ transform: 'translate(-50%, -50%) scale(0.9, 0.9)', offset: 0.6 }),
          style({ opacity: 0, transform: 'translate(-50%, -50%) scale(0.3, 0.3)', offset: 1 })
        ]))
      ]),
      query('.overlay', [
        style({ opacity: 1 }),
        animate('.5s', style({ opacity: 0 }))
      ])
    ])
  ])
]);

export const modalAnimationFlag = trigger('modalAnimationFlag', [
  transition(':enter', [
    group([
      query('.window', [
        style({ opacity: 1 }),
        animate('.5s', keyframes([
          style({ opacity: 0, transform: 'translate(-50%, -50%) scale(0.3, 0.3)', offset: 0 }),
          style({ opacity: 0.8, transform: 'translate(-50%, -50%) scale(0.9, 0.9)', offset: 0.2 }),
          style({ opacity: 1, transform: 'translate(-50%, -50%) scale(1.03, 1.03)', offset: 0.4 }),
          style({ opacity: 1, transform: 'translate(-50%, -50%) scale(0.97, 0.97)', offset: 0.6 }),
          style({ opacity: 1, transform: 'translate(-50%, -50%) scale(1, 1)', offset: 1 })
        ]))
      ]),
      query('.overlay', [
        style({ opacity: 0 }),
        animate('.5s', style({ opacity: 1 }))
      ])
    ])
  ]),
  transition(':leave', [
    group([
      query('.window', [
        style({ opacity: 1 }),
        animate('.5s', keyframes([
          style({ transform: 'translate(-50%, -50%) scale(1, 1)', offset: 0 }),
          style({ transform: 'translate(-50%, -50%) scale(0.97, 0.97)', offset: 0.2 }),
          style({ transform: 'translate(-50%, -50%) scale(1.03, 1.03)', offset: 0.4 }),
          style({ transform: 'translate(-50%, -50%) scale(0.9, 0.9)', offset: 0.6 }),
          style({ opacity: 0, transform: 'translate(-50%, -50%) scale(0.3, 0.3)', offset: 1 })
        ]))
      ]),
      query('.overlay', [
        style({ opacity: 1 }),
        animate('.5s', style({ opacity: 0 }))
      ])
    ])
  ])
]);

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('.5s', style({ opacity: 0 }))
  ])
]);

export const panelAnimation = trigger('panelAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(100%)' }),
    animate('.75s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 1, transform: 'translateY(0)' }))
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }),
    animate('.75s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 0, transform: 'translateY(100%)' }))
  ])
]);

export const stackedPanelAnimation = trigger('stackedPanelAnimation', [
  transition('hide => show', [
    style({ opacity: 0, transform: 'translateY(100%)' }),
    animate('.75s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 1, transform: 'translateY(0)' }))
  ]),
  transition('show => hide', [
    style({ opacity: 1, transform: 'translateY(0)' }),
    animate('.75s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 0, transform: 'translateY(100%)' }))
  ])
]);

export const searchBarAnimation = trigger('searchBarAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-100%)' }),
    animate('.75s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 1, transform: 'translateY(0)' }))
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }),
    animate('.75s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 0, transform: 'translateY(-100%)' }))
  ])
]);
