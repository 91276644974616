import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { modalAnimation } from 'src/app/utils/animations';

export interface Option {
  text: string,
  value: string
}

@Component({
  selector: 'modal-select',
  templateUrl: './modal-select.component.html',
  styleUrls: ['./modal-select.component.scss'],
  host: {
    '[@modalAnimation]': ''
  },
  animations: [
    modalAnimation
  ]
})
export class ModalSelectComponent implements OnInit {

  @Input() options: Option[] = [];
  @Input() title: string = "";
  @Output() close: EventEmitter<null> = new EventEmitter();
  @Output() select = new EventEmitter();
  
  constructor() { }

  ngOnInit() {}

  closeModal() {
    this.close.emit();
  }

}
