import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, IonNav } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { ApiService } from './services/api/api.service';
import { CategoryService } from './services/category/category.service';
import { ConnectionService } from './services/connection/connection.service';
import { DistanceService } from './services/distance/distance.service';
import { DrawerService } from './services/drawer/drawer.service';
import { FlagService } from './services/flag/flag.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { GoogleMapService } from './services/google-map/google-map.service';
import { GoogleMapSdkService } from './services/google-map-sdk/google-map-sdk.service';
import { GroupService } from './services/group/group.service';
import { LanguageService } from './services/language/language.service';
import { LocationService } from './services/location/location.service';
import { ModalService } from './services/modal/modal.service';
import { ResourceService } from './services/resource/resource.service';
import { ScreenService } from './services/screen/screen.service';
import { SearchService } from './services/search/search.service';
import { SubmissionService } from './services/submission/submission.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { LanguageModalModule } from './components/modals/language-modal/language-modal.module';
import { LanguageModalSelectModule } from './components/modal-select/language-modal-select/language-modal-select.module';
import { SubmissionModalModule } from './components/modals/submission-modal/submission-modal.module';
import { AboutModalModule } from './components/modals/about-modal/about-modal.module';
import { DownloadModalModule } from './components/modals/download-modal/download-modal.module';
import { IconsModalModule } from './components/modals/icons-modal/icons-modal.module';
import { HelplineModalModule } from './components/modals/helpline-modal/helpline-modal.module';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { CommonModule } from '@angular/common';
import { CategoriesModalModule } from './components/modals/categories-modal/categories-modal.module';
import { GoogleMapModule } from './components/google-map/google-map.module';
import { HTTP } from '@ionic-native/http/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Make sure a language code has been loaded before app starts (as some
// api requests are dependent on it being loaded from the start)
export function languageInitializer(languageProvider: LanguageService) {
  return () => languageProvider.getCode();
}

@NgModule({
  declarations: [
    AppComponent, 
    
  ],
  entryComponents: [
    AppComponent, 
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HammerModule,
    FormsModule,
    ReactiveFormsModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SidebarModule,
    CommonModule,
    LanguageModalModule,
    LanguageModalSelectModule,
    SubmissionModalModule,
    AboutModalModule,
    DownloadModalModule,
    CategoriesModalModule,
    IconsModalModule,
    HelplineModalModule,
    GoogleMapModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ApiService,
    CategoryService,
    ConnectionService,
    DistanceService,
    DrawerService,
    FlagService,
    GoogleAnalyticsService,
    GoogleMapService,
    GoogleMapSdkService,
    GroupService,
    LanguageService,
    LocationService,
    ModalService,
    ResourceService,
    ScreenService,
    SearchService,
    SubmissionService,
    Geolocation,
    InAppBrowser,
    Network,
    ScreenOrientation,
    {
      provide: APP_INITIALIZER,
      useFactory: languageInitializer,
      deps: [LanguageService],
      multi: true
    },
    Diagnostic,
    LocationAccuracy,
    Device,
    FormBuilder,
    IonNav,
    CallNumber,
    LaunchNavigator,
    HTTP
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
