import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ConnectionService } from 'src/app/services/connection/connection.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  @Output() linkClicked = new EventEmitter<string>();

  constructor(
    private platform: Platform,
    public inAppBrowser: InAppBrowser,
    public connectionProvider: ConnectionService
  ) { }

  ngOnInit() {}

  websiteClicked() {
    let url = 'https://usahello.org';
    if (this.platform.is('cordova')) {
      this.inAppBrowser.create(url, '_system');
    } else {
      window.open(url, '_blank');
    }
  }
}
