import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, fromEvent, BehaviorSubject} from 'rxjs';
import { Network } from '@ionic-native/network/ngx';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  private _connected: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public connected$: Observable<boolean> = this._connected.asObservable();
  public get connected() {
    return this._connected.getValue();
  }

  private onlineObservable;
  private offlineObservable;

  constructor(private platform: Platform, private network: Network) {

    let online;

    if (this.platform.is('cordova')) {
      this.onlineObservable = this.network.onConnect();
      this.offlineObservable = this.network.onDisconnect();
      online = (this.network.type !== 'none');
    } else {
      this.onlineObservable = fromEvent(window, 'online');
      this.offlineObservable = fromEvent(window, 'offline');
      online = navigator.onLine;
    }

    this.onlineObservable.subscribe((() => {
      console.debug('[Connection provider] App online');
      this._connected.next(true);
    }));

    this.offlineObservable.subscribe(() => {
      console.debug('[Connection provider] App offline');
      this._connected.next(false);
    });

    console.debug('[Connection provider] App starting:', (online ? 'online' : 'offline'));
    this._connected.next(online);
  }
}
