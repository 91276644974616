import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Option } from '../../components/radio-group/radio-group.component';
import { ApiService } from '../api/api.service';
import { ConnectionService } from '../connection/connection.service';
import { LanguageService } from '../language/language.service';
import { map } from 'rxjs/operators';

export interface Group {
  id: number,
  name: string
}

export interface GroupOption extends Option {
  isVisible: boolean
}

@Injectable({
  providedIn: 'root'
})
export class GroupService extends ApiService {

  constructor(
    public http: HttpClient,
    private storageProvider: Storage,
    public languageProvider: LanguageService,
    private translate: TranslateService,
    public connectionProvider: ConnectionService
  ) { 
    super(http, languageProvider)
  }

  private _groups: BehaviorSubject<Group[]> = new BehaviorSubject(<Group[]>[]);
  public groups$: Observable<Group[]> = this._groups.asObservable();
  public get groups() {
    return this._groups.getValue();
  }

  private _activeGroup: BehaviorSubject<Group> = new BehaviorSubject(undefined);
  public activeGroup$: Observable<Group> = this._activeGroup.asObservable();
  public get activeGroup() {
    return this._activeGroup.getValue();
  }

  /**
   * Options
   * 
   * Return the groups values as translation-friendly options fit for a
   * radio group
   */
  public groupOptions$: Observable<GroupOption[]> = new Observable(observer => {
    let translationObservable;
    let groupObservable = combineLatest(
      this.groups$,
      this.activeGroup$)
    .subscribe((values) => {
      let options = [];
      let [groups, activeGroup] = values;

      translationObservable = this.translate.get('list.groups.modal.all').subscribe((text: string) => {

        options = groups.map(group => {
          let checked = (typeof activeGroup !== 'undefined' && group['id'] === activeGroup['id']);
          return <GroupOption> {
            text: group['name'],
            value: group['id'].toString(),
            checked,
            isVisible: group['is_visible']
          }
        });

        options.unshift({
          text,
          value: undefined,
          checked: (typeof activeGroup === 'undefined'),
          isVisible: true
        });

        observer.next(options);
        observer.complete();
      });

    });

    return {unsubscribe() {
      groupObservable.unsubscribe();
      if (translationObservable) {
        translationObservable.unsubscribe();
      }
    }};
  });

  // public visibleGroupOptions$ = this.groupOptions$.forEach((options) =>{
  //   return options.filter(options => options.isVisible === true);
  // });

  public visibleGroupOptions$ = this.groupOptions$.pipe(map(item => {
    return item.filter(temp => temp.isVisible)
  }))

  /**
   * Update
   * 
   * Retreive all groups from the server
   */
  update() {
    if (this.connectionProvider.connected) {
      let url = `${this.apiURL()}groups`;
      this.http
        .get<any[]>(url)
        .subscribe(
          groups => {
            this.storageProvider
              .set('latestGroups', groups)
              .then(() => {
                console.debug('[Group provider] Groups persisted to storage:', groups);
              })
              .catch(err => {
                console.error('[Group provider] Error persisting groups to storage:', err);
              })
            this._groups.next(groups);
            this._activeGroup.next(undefined);
          },
          error => {
            console.error(error);
          });
    } else {
      this.storageProvider.get('latestGroups').then(groups => {
        console.debug(`[Group provider] Offline, fetched groups from storage:`, groups);
        this._groups.next(groups);
        this._activeGroup.next(undefined);
      });
    }
  }

  /**
   * Select
   * 
   * Select a single group as "active"
   */
  selectGroup(group: Group) {
    console.debug('Selecting group:', group['id']);
    let activeGroup = this.groups.find(group_ => (group_['id'] === group['id']));
    if (activeGroup) {
      this._activeGroup.next(activeGroup);
    }
  }

  /**
   * Deselect
   * 
   * Deselect any "active" group
   */
  deselectGroup() {
    console.debug('Deselecting all groups');
    this._activeGroup.next(undefined);
  }
}
