import { Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { IonNav, MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LanguageService } from './services/language/language.service';
import { ConnectionService } from './services/connection/connection.service';
import { Storage } from '@ionic/storage';
import { ScreenService } from './services/screen/screen.service';
import { TranslateService } from '@ngx-translate/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ModalService } from './services/modal/modal.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ListPage } from './pages/list/list.page';
import { DetailPage } from './pages/detail/detail.page';
import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  // template: `<ion-nav [root]="rootPage"></ion-nav>`
})

export class AppComponent implements OnInit {
  // rootPage: any;

  // @ViewChild('content') nav: IonNav;
  // @HostBinding('class')

  get currentLanguageClass(){
    return this.translationProvider.currentLang;
  };

  public showLanguageModal: boolean = false;
  public showLanguageModalSelect: boolean = false;
  public showSubmissionModal: boolean = false;
  public showAboutModal: boolean = false;
  public showCategoriesModal: boolean = false;
  public showDownloadModal: boolean = false;
  public showIconsModal: boolean = false;
  public showHelplineModal: boolean = false;
  public showLogo: boolean = true;
  private resizeTimeout: any;
  private submissionModalObserver: Subscription;
  public showLangulageSelect = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public language: LanguageService,
    public connectionProvider: ConnectionService,
    private storageProvider: Storage,
    private screenProvider: ScreenService,
    private translationProvider: TranslateService,
    private screenOrientation: ScreenOrientation,
    public modalProvider: ModalService,
    private googleAnalytics: GoogleAnalyticsService,
    private menuCtrl: MenuController,
    private router: Router,
    // private nav: IonNav
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.storageProvider
      .get("visitedWelcomeScreen")
      .then(visitedWelcomeScreen => {
        if (!visitedWelcomeScreen) {
          // this.rootPage = "welcome";
          this.router.navigate(['welcome'])
        } else {
          // this.rootPage = "list";
          this.router.navigate(['list'])
        }
      }).catch(err => {
        // this.rootPage = "welcome";
        this.router.navigate(['welcome'])
      });

    this.language.getCode().then(languageCode => {
      this.language.setCode(languageCode);
    });

    // Update screen size in the provider
    this.screenProvider.screenWidth = this.platform.width();
    this.screenProvider.screenHeight = this.platform.height();

    // Toggle submission modal
    this.submissionModalObserver = this.modalProvider.submissionModal$.subscribe((show) => {
      if (this.menuCtrl.isOpen()) {
        this.menuCtrl.close();
      } else {
        this.googleAnalytics.send('submission_started', 'global');
      }
      this.showSubmissionModal = show;
    });

    this.platform
      .ready()
      .then(() => {
        // Hide the logo if on mobile
        if (this.platform.is('mobile')) {
          this.showLogo = false;
        }

        // Hide device loading screen 
        if (this.platform.is('mobile')) {
          this.statusBar.overlaysWebView(false);
          this.statusBar.backgroundColorByHexString('#0b5cb2');
          this.splashScreen.hide();
        }

        // Show download modal on mobile web device only
        if (this.platform.is('mobileweb') && this.connectionProvider.connected) {
          this.storageProvider
            .get("showDownloadModal")
            .then(showDownloadModal => {
              if (showDownloadModal === null || typeof showDownloadModal === 'undefined') {
                showDownloadModal = true;
              }
              this.showDownloadModal = showDownloadModal;
            });
        }

        // Lock orientation
        if (this.platform.is('mobile')) {
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        }
      })
      .catch(err => console.error(err));
  }

  ngOnInit() {
    // this.storageProvider
    // .get("visitedWelcomeScreen")
    // .then(visitedWelcomeScreen => {
    //   if (! visitedWelcomeScreen) {
    //     this.router.navigate(['/welcome'])
    //   } else {

    //   }
    // }).catch(err => {

    // });
    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }
  }

  ngDestroy() {
    this.submissionModalObserver.unsubscribe();
  }

  sidebarLinkClicked(slug) {
    if (this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }

    switch (slug) {
      case "submission":
        this.openSubmitResourcePage();
        break;
      case "language":
        if (this.language.totalLanguages() > this.language.languageLimit) {
          this.toggleLanguageModalSelect();
        } else {
          this.toggleLanguageModal();
        }
        break;
      case "about":
        this.toggleAboutModal();
        break;
      case "categories":
        this.toggleCategoriesModal();
        break;
      case "icons":
        this.toggleIconsModal();
        break;
      case "helpline":
        this.toggleHelplineModal();
        break;
      default:
        break;
    }
  }

  /**
   * Toggle language modal
   * 
   */
  toggleLanguageModal() {
    if (this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }
    this.showLanguageModal = ! this.showLanguageModal;
  }
  /**
   * Toggle language modal select
   * 
   */
   toggleLanguageModalSelect() {
    if (this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }
    this.showLanguageModalSelect = !this.showLanguageModalSelect;
  }

  /**
   * Toggle about modal
   * 
   */
  toggleAboutModal() {
    if (this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }

    this.showAboutModal = !this.showAboutModal;
  }

  /**
   * Toggle categories modal
   * 
   */
  toggleCategoriesModal() {
    if (this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }
    this.showCategoriesModal = !this.showCategoriesModal;
  }

  /**
   * Toggle icons modal
   * 
   */
  toggleIconsModal() {
    if (this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }
    this.showIconsModal = !this.showIconsModal;
  }

  /**
   * Toggle helpline modal
   * 
   */
  toggleHelplineModal() {
    if (this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }
    this.showHelplineModal = !this.showHelplineModal;
  }

  /**
   * Close download modal
   * 
   */
  closeDownloadModal() {
    this.storageProvider
      .set("showDownloadModal", false)
      .then(() => {
        this.showDownloadModal = false;
      })
  }

  openSubmitResourcePage() {
    // const goToSubmitResourcePage = () => {
      // this.nav.push('submit-resource-page', {});
      this.router.navigate(['submit-resource'])
    // }

    // If leaving the list or detail page to go to the detail page, we need to
    // wait for the list page animation to finish before transitioning
    // if (this.nav.getActive().name === ListPage.name) {
    //   const listPage: ListPage = this.nav.getActive().instance;
    //   listPage.animatePageLeaving(goToSubmitResourcePage);
    // } else if (this.nav.getActive().name === DetailPage.name) {
    //   const detailPage: DetailPage = this.nav.getActive().instance;
    //   detailPage.animatePageLeaving(goToSubmitResourcePage);
    // } else {
    //   goToSubmitResourcePage();
    // }
  }

  resourceClicked(resource) {
    this.router.navigate(["detail", {id: resource.id || resource.pk}]);
    // const goToDetailPage = () => {
    //   this.router.navigate(['detail'])
    //   // this.nav.push('detail-page', {
    //   //   id: resourceId
    //   // });
    // }

    // If leaving the list page to go to the detail page, we need to
    // wait for the list page animation to finish before transitioning

    // if (this.nav.getActive().name === ListPage.name) {
    //   const listPage: ListPage = this.nav.getActive().instance;
    //   listPage.animatePageLeaving(goToDetailPage);
    // } else {
    //   goToDetailPage();
    // }
  }

  /**
   * On Window resize
   * 
   * Inform the screen provider with the new screen sizing once the resize
   * event has fully completed
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    // Wait for resize to finish before doing stuff
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      console.debug('[App component] Window resize event finished');
      this.screenProvider.screenWidth = event.target.innerWidth;
      this.screenProvider.screenHeight = event.target.innerHeight;
    }).bind(this), 500);
  }
}
