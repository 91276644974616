import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { modalAnimation } from 'src/app/utils/animations';

@Component({
  selector: 'categories-modal',
  templateUrl: './categories-modal.component.html',
  styleUrls: ['./categories-modal.component.scss'],
  host: {
    '[@modalAnimation]': ''
  },
  animations: [
    modalAnimation
  ],
  // encapsulation: ViewEncapsulation.None
})
export class CategoriesModalComponent implements OnInit {

  @Output() close: EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

}
