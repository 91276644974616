import { Injectable } from '@angular/core';
import { GoogleMapSdkService } from '../google-map-sdk/google-map-sdk.service';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {

  constructor(
    public googleSDKProvider: GoogleMapSdkService
  ) { }

  private _center = new BehaviorSubject<object>({
    latitude: this.googleSDKProvider.DEFAULT_LATITUDE,
    longitude: this.googleSDKProvider.DEFAULT_LONGITUDE,
    // FIXME: We need to be able to tell when the center of the map has
    // been changed by the app, or by the google map itself via a drag event
    source: 'app' 
  });
  public center$: Observable<object> = this._center.asObservable();

  public get center() {
    return this._center.getValue();
  }

  public set center(center) {
    if (typeof center['source'] === 'undefined') {
      center['source'] = 'app';
    }
    this._center.next(center);
  }

  private _zoom = new BehaviorSubject<number>(this.googleSDKProvider.DEFAULT_ZOOM);
  public zoom$: Observable<number> = this._zoom.asObservable();

  public get zoom() {
    return this._zoom.getValue();
  }

  public set zoom(value) {
    if (value < 0) {
      value = 0
    } else if (value > 20) {
      value = 20;
    }
    this._zoom.next(value);
  }
}
