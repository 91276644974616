import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CategoryService } from 'src/app/services/category/category.service';
import { DistanceService } from 'src/app/services/distance/distance.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
import { GroupService } from 'src/app/services/group/group.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { childAnimations } from 'src/app/utils/animations';
import { ModalSelectComponent } from '../modal-select.component';

@Component({
  selector: 'language-modal-select',
  templateUrl: './language-modal-select.component.html',
  styleUrls: ['./language-modal-select.component.scss'],
  host: {
    '[@childAnimations]': ''
  },
  animations: [
    childAnimations
  ]
})
export class LanguageModalSelectComponent implements OnInit {

  @ViewChild('modal') modal: ModalSelectComponent;
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(
    public languageProvider: LanguageService,
    public categoryProvider: CategoryService,
    public resourceProvider: ResourceService,
    public groupProvider: GroupService,
    public distanceProvider: DistanceService,
    public googleAnalytics: GoogleAnalyticsService
  ) { }

  ngOnInit() {}

  save(languageCode) {
    this.googleAnalytics.send('language_changed', 'global', languageCode);
    if (languageCode !== this.languageProvider.getCode()) {
      this.modal.closeModal();
    }
    this.languageProvider.setCode(languageCode).then(() => {
      // Refetch resources, categories, distances and groups
      this.groupProvider.update();
      this.categoryProvider.update();
      this.resourceProvider.update();
      this.distanceProvider.activeDistance = -1;
    });
  }
}
