import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  private breakpoint = 768;

  constructor(
    public platform: Platform
  ) { }

  /**
   * View mode
   * 
   * 
   */
  private _viewMode = new BehaviorSubject<any>({
    isMobile: this.platform.width() <= this.breakpoint,
    changed: false
  });
  public viewMode$: Observable<any> = this._viewMode.asObservable();

  public get viewMode() {
    return this._viewMode.getValue();
  }

  public set viewMode(viewMode) {
    this._viewMode.next(viewMode);
  }

  /**
   * Screen width
   * 
   */
  private _screenWidth = new BehaviorSubject<number>(this.platform.width());
  public screenWidth$: Observable<number> = this._screenWidth.asObservable();

  public set screenWidth(screenWidth: number) {
    console.debug('[Screen provider] Updating screen width to:', screenWidth);
    
    // Mobile
    if (screenWidth <= this.breakpoint) {
      this.viewMode = {
        isMobile: true,
        changed: this.viewMode.isMobile === false
      };
    }
    // Desktop
    else {
      this.viewMode = {
        isMobile: false,
        changed: this.viewMode.isMobile === true
      };
    }
    this._screenWidth.next(screenWidth);
  }

  /**
   * Screen height
   * 
   */
  private _screenHeight= new BehaviorSubject<number>(this.platform.height());
  public screenHeight$: Observable<number> = this._screenHeight.asObservable();

  public set screenHeight(screenHeight: number) {
    console.debug('[Screen provider] Updating screen height to:', screenHeight);
    this._screenHeight.next(screenHeight);
  }
}
