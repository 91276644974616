import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value: string, query): string {
    if (query) {
      let regex = new RegExp(query, 'i');
      let matches = value.match(regex);
      if (! matches || matches.length <= 0) {
        return value;
      }
      return value.replace(regex, `<span class="highlight">${matches[0]}</span>`)
    }
    return value;
  }

}
