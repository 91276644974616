import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Platform } from '@ionic/angular';
import { modalAnimation } from 'src/app/utils/animations';

@Component({
  selector: 'about-modal',
  templateUrl: './about-modal.component.html',
  styleUrls: ['./about-modal.component.scss'],
  host: {
    '[@modalAnimation]': ''
  },
  animations: [
    modalAnimation
  ],
  encapsulation: ViewEncapsulation.None
})
export class AboutModalComponent implements OnInit {

  @Output() close: EventEmitter<void> = new EventEmitter();
  public showDownloadButton: boolean = false;

  constructor(public platform: Platform) {
    if (this.platform.is('mobileweb') && !this.platform.is('desktop')) {
      this.showDownloadButton = true;
    }
  }

  ngOnInit() { }

  downloadClicked() {
    if (this.platform.is('android')) {
      window.open('https://play.google.com/store/apps/details?id=com.therefugeecenter.findhello');
    }
    else if (this.platform.is('ios')) {
      window.open('https://itunes.apple.com/gb/app/findhello-immigrant-help/id1446325524');
    }
  }
}
