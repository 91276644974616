import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { Storage } from '@ionic/storage';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { CategoryService } from '../category/category.service';
import { ConnectionService } from '../connection/connection.service';
import { DistanceService } from '../distance/distance.service';
import { GoogleMapService } from '../google-map/google-map.service';
import { GroupService } from '../group/group.service';
import { LanguageFilterService } from '../language-filter/language-filter.service';
import { LanguageService } from '../language/language.service';
import { LocationService } from '../location/location.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceService extends ApiService {

  private _resources: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public resources$: Observable<any[]> = this._resources.asObservable();
  public get resources() {
    return this._resources.getValue();
  }

  constructor(
    public http: HttpClient,
    public language: LanguageService,
    private groupProvider: GroupService,
    private languageFilterProvider: LanguageFilterService,
    private categoryProvider: CategoryService,
    private locationProvider: LocationService,
    private googleMapProvider: GoogleMapService,
    private distanceProvider: DistanceService,
    private connectionProvider: ConnectionService,
    private storageProvider: Storage
  ) { 
    super(http, language);
  }

  /**
   * Update all resources
   * 
   * Update the resources from either the API (if online) or storage if 
   * there's anything there. The following queryparams are sent to the server:
   * 
   * - c_lat: client latitude
   * - c_lng: client longitude
   * - m_lat: map center latitude
   * - m_lng: map center longitude
   * - category: id for category to filter by
   * - group: id for group to filter by
   */
  update() {
    if (this.connectionProvider.connected) {
      const mapCenter = this.googleMapProvider.center;
      const position = this.locationProvider.position;
      const group = this.groupProvider.activeGroup;
      const languageFilter = this.languageFilterProvider.activeLanguageFilter;
      const category = this.categoryProvider.activeCategory;
      const distance = this.distanceProvider.activeDistance;

      let params = {};
      
      if (typeof mapCenter !== 'undefined') {
        params['m_lat'] = mapCenter['latitude'];
        params['m_lng'] = mapCenter['longitude'];
      }

      if (typeof category !== 'undefined') {
        params['category'] = category['id'];
      }

      if (typeof group !== 'undefined') {
        params['group'] = group['id'];
      }

      if (typeof distance !== 'undefined') {
        params['distance'] = distance;
      }
    
      if (typeof position !== 'undefined') {
        params['c_lat'] = position.coords.latitude;
        params['c_lng'] = position.coords.longitude
      }

      if (typeof languageFilter !== 'undefined') {
        params['lang'] = languageFilter['name'].toLowerCase();
      }

      console.debug('[Resource provider] Fetching resources with params:', params);

      let url = `${this.apiURL()}resources`;
      this.http
        .get<any[]>(url, {params})
        .subscribe(
          resources => {
            console.debug(`[Resource provider] Fetched ${resources.length} resource(s)`);
            console.debug(`[Resource provider] Lat: ${mapCenter['latitude']}, Lng: ${mapCenter['longitude']}`);

            // Only save the resources to storage if it's a regular query, without
            // any categories, groups or distance filters
            if (! ('category' in params || 'group' in params )) {
              this.storageProvider
                .set('latestResources', resources)
                .then(() => {
                  console.debug('[Resource provider] Resources persisted to storage:', resources);
                })
                .catch(err => {
                  console.error('[Resource provider] Error persisting resources to storage:', err);
                })
            }
            this._resources.next(resources);
          },
          error => {
            console.error(error);
          });
    } else {
      this.storageProvider.get('latestResources').then(resources => {
        console.debug(`[Resource provider] Offline, fetched resources from storage:`, resources);
        this._resources.next(resources);
      });
    }
  }

  /**
   * Get a single resource
   * 
   * Retreive a single resource from either the API or the storage if there's
   * anything there.
   */
  retrieve(id, params): Observable<any> {
    if (this.connectionProvider.connected) {
      let url = `${this.apiURL()}resources/${id}`;
      return this.http.get<any[]>(url, {params});
    } else {
      console.debug(`[Resource provider] Offline, fetching resource from storage`);
      return new Observable(observer => {
        let resource = this.resources.find(resource => {
          return (resource.id == id);
        });
        observer.next(resource);
      });
    }
  }
}
