import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ModalModule } from '../../modal/modal.module';
import { PanelModule } from '../../panel/panel.module';
import { CategoriesModalComponent } from './categories-modal.component';

@NgModule({
  declarations: [
    CategoriesModalComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    ModalModule,
    PanelModule,
    TranslateModule.forChild(),
  ],
  exports: [
    CategoriesModalComponent
  ]
})
export class CategoriesModalModule {}