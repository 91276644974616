import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { SubmissionStepOutroComponent } from './submission-step-outro.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    SubmissionStepOutroComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,    
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SubmissionStepOutroComponent
  ]
})
export class SubmissionStepOutroModule {}