import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ModalSelectComponent } from './modal-select.component';
import { IonicModule } from '@ionic/angular';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';

@NgModule({
  declarations: [
    ModalSelectComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ScrollbarModule
  ],
  exports: [
    ModalSelectComponent
  ]
})
export class ModalSelectModule {}