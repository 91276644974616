import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from './sidebar.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
  ],
  exports: [
    SidebarComponent
  ]
})
export class SidebarModule {}