import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }

  private _submissionModal = new BehaviorSubject<boolean>(false);
  public submissionModal$: Observable<boolean> = this._submissionModal.asObservable();

  public toggleSubmissionModal(show: boolean) {
    console.debug('[Modal provider] Toggling submission modal');
    this._submissionModal.next(show);
  }
}
