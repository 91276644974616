import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { WizardStepComponent } from '../../wizard-step/wizard-step.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'submission-step-name',
  templateUrl: './submission-step-name.component.html',
  styleUrls: ['./submission-step-name.component.scss'],
  providers: [{provide: WizardStepComponent, useExisting: forwardRef(() => SubmissionStepNameComponent)}]
})
export class SubmissionStepNameComponent extends WizardStepComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public typeOptions: Array<Object> = [];
  private observers = [];

  @Input() public formData;
  @Output() typeChanged: EventEmitter<string> = new EventEmitter<string>();

  // Alias the isValid property so that the parent wizard can check when step is complete
  get isValid() {
    return this.form['valid'];
  }
  
  constructor(
    private fb: FormBuilder,
    private translateProvider: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    // Setup form
    this.form = this.fb.group({
      type: [undefined, [Validators.required]],
      name: ["", [Validators.required, Validators.maxLength(100)]],
      isNationwide: [false, [Validators.required]]
    });

    // Create type options and translations
    let options = [
      'physical',
      'digital'
    ], keys = options.map(option => `global.submission.name.form.type.options.${option}`);


    this.observers[0] = this.translateProvider
      .get(keys)
      .subscribe(values => {
        
        this.typeOptions = options.map(option => {
          return {
            value: option,
            name: values[`global.submission.name.form.type.options.${option}`]
          }
        });

        // Set default
        this.form.controls.type.setValue(options[0]);
      });

    // Write changes to data model
    this.observers[1] = this.form.valueChanges.subscribe(form => {
      this.formData.type = form.type;
      this.formData.name = form.name;
      this.formData.is_nationwide = form.isNationwide;
    });
  }

  ngOnDestroy() {
    for (let i = 0; i < this.observers.length; i++) {
      this.observers[i].unsubscribe();
    }
  }

}
