import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WizardStepComponent } from '../../wizard-step/wizard-step.component';

@Component({
  selector: 'submission-step-details',
  templateUrl: './submission-step-details.component.html',
  styleUrls: ['./submission-step-details.component.scss'],
  providers: [{provide: WizardStepComponent, useExisting: forwardRef(() => SubmissionStepDetailsComponent)}]
})
export class SubmissionStepDetailsComponent extends WizardStepComponent implements OnInit {

  @Input() public formData;
  public form: FormGroup;

  // Alias the isValid property so that the parent wizard can check when step is complete
  get isValid() {
    return this.form['valid'];
  }

  constructor(
    private fb: FormBuilder
  ) { 
    super();
  }

  log(val) { console.debug(val); }


  ngOnInit() {
    // let phoneValidator = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    // let websiteValidator = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  
    // Setup form
     // Setup form
     this.form = this.fb.group({
      requires_photo_id_or_documents:["true"], documents_required:[""], languages_spoken:[""]
    });
   
    
    // Write changes to data model
    this.form.valueChanges.subscribe(form => {

      
      //greying out ID field
      let elem = document.getElementById("formOfID");
      (form.requires_photo_id_or_documents == 'true') ? elem.setAttribute("disabled","false") : elem.setAttribute("disabled","true");

      //updating formData
      this.formData.requires_photo_id_or_documents = form.requires_photo_id_or_documents;
      this.formData.documents_required = form.documents_required;
      if (form.languages_spoken){
        this.formData.languages_spoken = form.languages_spoken.join(", ");
      }
    });
  }
  
}
