import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';

import { Observable, BehaviorSubject } from 'rxjs';

const BEVELESS_MODELS = ['iPhone10', 'iPhone11'];

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  constructor(
    public platform: Platform,
    public device: Device
  ) { }

  /**
   * Position top
   * 
   */
  private _positionTop: BehaviorSubject<number> = new BehaviorSubject(0);
  public positionTop$: Observable<number> = this._positionTop.asObservable();

  public get positionTop(): number {
    return this._positionTop.getValue();
  }

  public set positionTop(newPositionTop: number) {
    this._positionTop.next(newPositionTop);
  }

  /**
   * Movement
   * 
   */
  private _movement = new BehaviorSubject<any>({ 'evt': 'none', 'posY': 0, 'isFinal': true });
  public movement$: Observable<any> = this._movement.asObservable();

  public set movement(newMovement: any) {
    this._movement.next(newMovement);
  }

  /**
   * Beveless devices
   * 
   * Devices like the iPhone XR need to have the drawer positining bumped up
   */
  private isBevelessDevice() {
    if (this.platform.is('cordova')) {
      return BEVELESS_MODELS.some(model => this.device.model.includes(model));
    }
    return false;    
  }

  /**
   * Bottom gap
   * 
   */
  public getSafeBottomGap(value) {
    if (this.isBevelessDevice()) {
      return value + 35; // iPhone X
    } else if (this.platform.is('ios')) {
      return value + 10 // Non X iPhones
    }
    return value;
  }

  /**
   * Collapsed height
   * 
   */
  public getSafeCollapsedHeight(value) {
    if (this.isBevelessDevice()) {
      return value + 80; // iPhone X
    } else if (this.platform.is('ios')) {
      return value// + 20 // Non X iPhones
    }
    return value;
  }
}
