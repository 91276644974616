import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
import { Submission, SubmissionService } from 'src/app/services/submission/submission.service';
import { childAnimations } from 'src/app/utils/animations';
import { WizardStepComponent } from '../wizard-step/wizard-step.component';
import { WizardComponent } from '../wizard/wizard.component';

@Component({
  selector: 'submission-modal',
  templateUrl: './submission-modal.component.html',
  styleUrls: ['./submission-modal.component.scss'],
  host: {
    '[@childAnimations]': ''
  },
  animations: [
    childAnimations
  ]
})
export class SubmissionModalComponent implements OnInit {

  @ViewChild('wizard') wizard: WizardComponent;
  @ViewChild('addressSubmissionStep') addressSubmissionStep: WizardStepComponent;
  @ViewChild('nameSubmissionStep') nameSubmissionStep: WizardStepComponent;
  @ViewChild('detailsSubmissionStep') detailsSubmissionStep: WizardStepComponent;
  @Output() close: EventEmitter<void> = new EventEmitter();

  public formData: Submission;
  public showConfirm: Boolean;

  constructor(
    private submissionProvider: SubmissionService,
    private toastCtrl: ToastController,
    private googleAnalytics: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.showConfirm = true;
    this.formData = {
      "type": "physical",
      "name": "",
      "is_nationwide": false,
      "address": "",
      "city": "",
      "state": "",
      "zipcode": "",
      "website": "",
      "phone": "",
      "description": "",
      "categories": [],
      "groups": [],
      "submitter_first_name": "",
      "submitter_last_name": "",
      "submitter_email": "",
      "point": {},
      "google_places_id": ""
    }
  }

  typeChanged(value) {
    // Remove the address and details steps if the resource is online-only
    this.addressSubmissionStep['hidden'] = (value.detail.value == 'digital');
    this.detailsSubmissionStep['hidden'] = (value.detail.value == 'digital');
  }

  createSubmission() {
    this.submissionProvider.create(this.formData)
      .subscribe(
        (result) => {
          this.googleAnalytics.send('submission_completed', 'global', result.name);
          this.showConfirm = false;
          this.wizard.next();
        },
        async (error) => {
          const toast = await this.toastCtrl.create({
            message: 'Server error',
            duration: 2000,
            cssClass: 'error',
            position: 'bottom'
          });
          toast.present();

          this.wizard.goToStep(this.nameSubmissionStep);
        }
      )
  }

}
