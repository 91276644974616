import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ConnectionService } from 'src/app/services/connection/connection.service';
import { DrawerService } from 'src/app/services/drawer/drawer.service';
import { GoogleMapSdkService } from 'src/app/services/google-map-sdk/google-map-sdk.service';
import { GoogleMapService } from 'src/app/services/google-map/google-map.service';
import { LocationService } from 'src/app/services/location/location.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { ScreenService } from 'src/app/services/screen/screen.service';

declare var MarkerWithLabel;
// import * as googel.map from 'googlemaps';

@Component({
  selector: 'google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnDestroy {

  @ViewChild('mapContainer', {static: true}) mapElement;
  @Output() markerClicked: EventEmitter<null> = new EventEmitter();

  public showPlaceholder: boolean = true;

  private markerLabelThreshold: number = 14;
  private map: google.maps.Map;
  private markers: MarkerWithLabel[] = [];
  private totalMarkersCount: number = 0;
  private positionMarker: google.maps.Marker;
  private projection: google.maps.Projection;  
  private positionObserver$;
  private connectionObserver$;
  private googleSDKObserver$;
  private resourceObserver$;
  private centerObserver$;
  private zoomObserver$;
  private screenObserver$;

  constructor(
    private zone: NgZone,
    public connectionProvider: ConnectionService,
    public platform: Platform,
    private googleMapProvider: GoogleMapService,
    private googleSDKProvider: GoogleMapSdkService,
    private drawerProvider: DrawerService,
    private screenProvider: ScreenService,
    private resourceProvider: ResourceService,
    private locationProvider: LocationService
  ) { }

  ngOnInit() {
    // Initilize the map
    this.googleSDKObserver$ = this.googleSDKProvider.loaded$.subscribe((loaded) => {
      if (loaded) {
        this.initMap();
      }
    });

    // Deal with online/offline
    this.connectionObserver$ = this.connectionProvider.connected$.subscribe(isConnected => {
      this.showPlaceholder = !isConnected;
    });
  }

  ngOnDestroy() {
    if (this.connectionObserver$) {
      this.connectionObserver$.unsubscribe();
    }
    if (this.positionObserver$) {
      this.positionObserver$.unsubscribe();
    }
    if (this.googleSDKObserver$) {
      this.googleSDKObserver$.unsubscribe();
    }
    if (this.resourceObserver$) {
      this.resourceObserver$.unsubscribe();
    }
    if (this.centerObserver$) {
      this.centerObserver$.unsubscribe();
    }
    if (this.zoomObserver$) {
      this.zoomObserver$.unsubscribe();
    }
    if (this.screenObserver$) {
      this.screenObserver$.unsubscribe();
    }
  }

  private initMap() {

    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      center: {
        lat: this.googleMapProvider.center['latitude'],
        lng: this.googleMapProvider.center['longitude']
      },
      zoom: this.googleMapProvider['zoom'],
      disableDefaultUI: true,
      zoomControl: this.platform.is('cordova')
    });

    // Update projection (used for mobile-aware movement)
    google.maps.event.addListenerOnce(this.map, "projection_changed", () => {
     this.projection = this.map.getProjection();
    });

    // Update the provider when the center value changes
    this.map.addListener('dragend', () => {
      this.zone.run(() => {
        console.debug('[Google Map component] Gmap drag event finished');
        let center = this.map.getCenter();
        this.googleMapProvider.center = {
          latitude: center.lat(),
          longitude: center.lng(),
          source: 'map'
        }
      });
    });

    // Move the map when the center changes
    this.centerObserver$ = this.googleMapProvider.center$.subscribe(center => {
      console.debug('[Google Map component] New provider center detected');
      let currentCenter = this.map.getCenter();
      let currentLatLng = new google.maps.LatLng(currentCenter.lat(), currentCenter.lng());
      let latLng = new google.maps.LatLng(center['latitude'], center['longitude']);
      
      if (currentLatLng !== center) {
        // Adjust the latitude we are moving to if the user is on mobile
        if (this.screenProvider.viewMode.isMobile && this.projection && center['source'] === 'app') {
          const scale = Math.pow(2, this.map.getZoom());
          const paddingTop = 85;
          const panelPositionTop = this.drawerProvider.positionTop || this.platform.height();
          const center = (this.platform.height() / 2)
          const offsetCenter = ((panelPositionTop - paddingTop) / 2) + paddingTop;
          const offset = center - offsetCenter;
          console.debug(scale, offset)
          const pointA = this.projection.fromLatLngToPoint(latLng);
          const pointB = new google.maps.Point(0, offset / scale);
          const pointC = new google.maps.Point(pointA.x, pointA.y + pointB.y);
          latLng = this.projection.fromPointToLatLng(pointC);
        }
        this.map.panTo(latLng);
        this.resourceProvider.update();
      } else {
        console.debug('[Google Map component] Provider pan handler skipped');
      }
    });

    // Update the provider when the zoom value changes
    this.map.addListener('zoom_changed', () => {
      this.zone.run(() => {
        console.debug('[Google Map component] Gmap zoom event finished');
        let zoom = this.map.getZoom();
        this.googleMapProvider.zoom = zoom;
        this.labelsVisible((zoom > this.markerLabelThreshold));
      });
    });

    // Update zoom when provider changes
    this.zoomObserver$ = this.googleMapProvider.zoom$.subscribe(zoom => {
      console.debug('[Google Map component] New provider zoom detected');
      let currentZoom = this.map.getZoom();
      if (currentZoom !== this.googleMapProvider.zoom) {
        this.map.setZoom(zoom);
      } else {
        console.debug('[Google Map component] Provider zoom handler skipped');
      }
    });

    // Update markers on map when resources change
    this.resourceObserver$ = this.resourceProvider.resources$.subscribe(resources => {
      console.debug('[Google Map component] New provider resources detected');
  
      // Add new markers
      let marker: MarkerWithLabel,
          id: number,
          ids: Array<number> = resources.map(resource => resource['id']),
          hidden: number = 0,
          displayed: number = 0,
          created: number = 0,
          deleted: number = 0,
          skipped: number = 0,
          that = this,
          labelVisible: boolean = (this.map.getZoom() > this.markerLabelThreshold);

      for (let i = 0; i < resources.length; i++) {
        
        if (resources[i]['type'] === 'physical') {
          id = resources[i]['id'];
          
          // Marker doesn't exist, create it
          if (typeof this.markers[id] === 'undefined') {
            marker = new MarkerWithLabel({
              position: new google.maps.LatLng(resources[i]['latitude'], resources[i]['longitude']),
              map: this.map,
              labelContent: resources[i]['name'],
              labelAnchor: new google.maps.Point(0, 70),  // Vertical positioning is handled by CSS with "transform"
              labelClass: 'map-marker-label',
              labelVisible,
              icon: {
                url: 'assets/imgs/map_pin.png',
                scaledSize: new google.maps.Size(60, 48),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(30, 48),
                labelOrigin: new google.maps.Point(0, -10)
              },
              labelStyle: {
                'transform': 'translate3d(-50%, 0, 0)',
                'background-color': '#0b5cb2',
                'color': 'white',
                'font-size': '10px',
                'line-height': '10px',
                'font-weight': 'bold',
                'border-radius': '9px',
                'padding': '4px 8px',
                'max-width': '140px',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
              }
            });

            marker.addListener('click', () => {
              console.debug("[Google Map component] Marker clicked");
              this.markerClicked.emit(resources[i]);
            });

            marker.addListener('mouseover', function() {
              if (this.map.getZoom() <= that.markerLabelThreshold) {
                this.set("labelVisible", true);
                this.label.draw();
              }
            });

            marker.addListener('mouseout', function() {
              if (this.map.getZoom() <= that.markerLabelThreshold) {
                this.set("labelVisible", false);
                this.label.draw();
              }
            });

            this.markers[id] = marker;
            this.totalMarkersCount++;
            created++;
          } else {
            // Marker already exists, display it if hidden
            marker = this.markers[id];
            if (! marker.getVisible()) {
              this.markers[id].setVisible(true);
              displayed++;
            }
          }
        } else {
          skipped++;
        }
      } 

      // Delete or hide stale markers
      for (let j = 0; j < this.markers.length; j++) {
        marker = this.markers[j];
        if (typeof marker !== 'undefined' && ids.indexOf(j) === -1) {
          if (this.totalMarkersCount > 1000) {
            this.markers[j].setMap(null);
            delete this.markers[j];
            deleted++;
            this.totalMarkersCount--;
          } else {
            if (this.markers[j].getVisible()) {
              this.markers[j].setVisible(false);
              hidden++;
            }
          }
          
        }
      }

      console.debug(`[Google Map component] Created ${created} marker(s)`);
      console.debug(`[Google Map component] Deleted ${deleted} marker(s)`);
      console.debug(`[Google Map component] Hidden ${hidden} marker(s)`);
      console.debug(`[Google Map component] Displayed ${displayed} marker(s)`);
      console.debug(`[Google Map component] Skipped ${skipped} marker(s)`);
    });
  
    // Update map when user's location changes
    this.positionObserver$ = this.locationProvider.position$.subscribe((position) => {
      if (typeof position !== 'undefined') {
        let latitude = position['coords']['latitude'];
        let longitude = position['coords']['longitude'];
        if (this.positionMarker) {
          // Update existing position marker
          this.positionMarker.setPosition(new google.maps.LatLng(
            latitude,
            longitude
          ));
        } else {
          // Create new position marker
          this.positionMarker = new google.maps.Marker({
            position: new google.maps.LatLng(
              latitude,
              longitude
            ),
            map: this.map,
            animation: google.maps.Animation.DROP,
            icon: {
              url: 'assets/imgs/location_pin.png',
              scaledSize: new google.maps.Size(24, 24),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(12, 12)
            }
          });
        }
        // Move map
        this.googleMapProvider.zoom = 14;
        this.googleMapProvider.center = {
          latitude,
          longitude
        }
      } else {
        // Clear the position marker
        if (this.positionMarker) {
          this.positionMarker.setMap(null);
          this.positionMarker = null;
        } else {
          console.warn("Position marker does not exist");
        }
      }
    });

    // Hide zoom controls on mobile
    this.screenObserver$ = this.screenProvider.viewMode$.subscribe((viewMode) => {
      console.debug('[Google Map component] Toggling zoom controls');
      this.map.set('zoomControl', ! viewMode.isMobile);
    });
  }

  private labelsVisible(value: boolean): void {
    let marker;
    for (let i = 0; i < this.markers.length; i++) {
      marker = this.markers[i];
      if (typeof marker !== 'undefined') {
        marker.set("labelVisible", value);
        marker.label.draw();
      }
    }
  }

}
