import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../../../directives/directives.module';
import { SubmissionStepContactComponent } from './submission-step-contact.component';
import { IonicModule } from '@ionic/angular';
import { BrMaskerModule } from 'br-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SubmissionStepContactComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    IonicModule,
    TranslateModule.forChild(),
    BrMaskerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SubmissionStepContactComponent
  ]
})
export class SubmissionStepContactModule {}