import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { modalAnimation } from 'src/app/utils/animations';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  host: {
    '[@modalAnimation]': ''
  },
  animations: [
    modalAnimation
  ]
})
export class ModalComponent implements OnInit {

  @Input() title: string;
  @Input() showConfirm: boolean = false;
  @Input() showClose: boolean = false;
  @Output() close: EventEmitter<null> = new EventEmitter();

  constructor(
    public element: ElementRef,
    public renderer: Renderer2,
    private alertCtrl: AlertController,
    private translate: TranslateService
  ) { }

  ngOnInit() {}

  closeModal({ force = false } = {}) {
    if (this.showConfirm && !force) {
      this.translate.get([
        'general.yes',
        'general.no',
        'global.modal.confirmation.message'
      ]).subscribe(async translations => {
        // this.close.emit();
        // this.alertCtrl.create({
        //   message: translations['global.modal.confirmation.message'],
        //   buttons: [
        //     {
        //       text: translations['general.no'],
        //       handler: () => {  
        //       }
        //     },
        //     {
        //       text: translations['general.yes'],
        //       handler: () => {
        //         this.close.emit();
        //       }
        //     }
        //   ]
        // }).present();

        const alert = await this.alertCtrl.create({
          message: translations['global.modal.confirmation.message'],
          buttons: [
            {
              text: translations['general.no'],
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                
              }
            }, {
              text: translations['general.yes'],
              handler: () => {
                this.close.emit();
              }
            }
          ]
        });
    
        await alert.present();
      });
    } else {
      this.close.emit();
    }
  }
}
