import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../language/language.service';



@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public http: HttpClient, public language: LanguageService) { 

  }

  apiURL(translatable: boolean = true) {
    let url = environment.API_URL;
    if (translatable) {
      url += `/${this.language.currentLanguage}/`
    }
    return url;
  }
}
