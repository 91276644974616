import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ConnectionService } from 'src/app/services/connection/connection.service';

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  host: {
    'class': 'panel'
  }
})
export class PanelComponent {

  @Output() headerClick: EventEmitter<null> = new EventEmitter();
  @Input() @HostBinding('class.has-header') headerText: string;
  @HostBinding('class.is-loading') isLoading: boolean = false;
  
  constructor(public connectionProvider: ConnectionService) { }
}
