import { Injectable } from '@angular/core';
import { Option } from '../../components/radio-group/radio-group.component';
import { Observable, BehaviorSubject } from 'rxjs/';

import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class DistanceService {

  public distances: Array<number> = [2, 5, 10, 25, 50, 100];

  private _activeDistance: BehaviorSubject<number> = new BehaviorSubject(25);
  public activeDistance$: Observable<number> = this._activeDistance.asObservable();

  public get activeDistance() {
    return this._activeDistance.getValue();
  }

  public set activeDistance(value: number) {
    if (this.distances.indexOf(value) !== -1) {
      this._activeDistance.next(value);
    } else {
      this._activeDistance.next(25);
    }
  }

  /**
   * Options
   * 
   * Return the distances values as translation-friendly options fit for a
   * radio group
   */
  public options$: Observable<Option[]> = new Observable(observer => {
    let translationKeys = ['general.miles'];
    let translationObserver = this.translate.get(translationKeys).subscribe(translations => {
      let options = this.distances.map(distance => {
        let checked = (distance === this.activeDistance);
        return <Option> {
          text: `${distance} ${translations['general.miles']}`,
          value: `${distance}`,
          checked
        }
      });
  
      observer.next(options);
      observer.complete();
    });

    return {
      unsubscribe() {
        translationObserver.unsubscribe();
      }
    }
  });

  public constructor(
    private translate: TranslateService
  ) {}
}
