import { NgModule } from '@angular/core';
import { HighlightPipe } from './highlight/highlight.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
@NgModule({
    declarations: [
        HighlightPipe,
        TruncatePipe
    ],
    imports: [],
    exports: [
        HighlightPipe,
        TruncatePipe
    ]
})

export class PipesModule { }