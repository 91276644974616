import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { LanguageService } from '../language/language.service';

export interface Submission {
  name: string,
  type: string,
  is_nationwide: boolean,
  address: string,
  city: string,
  state: string,
  zipcode: string,
  website: string,
  phone: string,
  description: string,
  categories: Array<number>,
  groups: Array<number>,
  submitter_first_name: string,
  submitter_last_name: string,
  submitter_email: string,
  point: Object,
  google_places_id: string,
}

@Injectable({
  providedIn: 'root'
})
export class SubmissionService extends ApiService {

  get url(): string {
    return `${this.apiURL()}submissions`;
  }

  constructor(
    public http: HttpClient,
    public languageProvider: LanguageService
  ) { 
    super(http, languageProvider);
  }

  public create(data: Object): Observable<Submission>{
    return this.http.post<Submission>(this.url, data);
  }
}
