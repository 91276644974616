import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { WizardStepComponent } from '../../wizard-step/wizard-step.component';

@Component({
  selector: 'submission-step-outro',
  templateUrl: './submission-step-outro.component.html',
  styleUrls: ['./submission-step-outro.component.scss'],
  providers: [{ provide: WizardStepComponent, useExisting: forwardRef(() => SubmissionStepOutroComponent) }]
})
export class SubmissionStepOutroComponent extends WizardStepComponent {

  @Output() start: EventEmitter<WizardStepComponent> = new EventEmitter<WizardStepComponent>();

  constructor() {
    super();

    this.showProgress = false;
    this.isValid = true;
    this.showFooter = false;
  }
}
