import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { WizardStepComponent } from './wizard-step.component';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [
    WizardStepComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,    
    TranslateModule.forChild(),
  ],
  exports: [
    WizardStepComponent
  ]
})
export class WizardStepModule {}