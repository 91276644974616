import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { states } from 'src/app/data/states';
import { ConnectionService } from 'src/app/services/connection/connection.service';
import { GoogleMapSdkService } from 'src/app/services/google-map-sdk/google-map-sdk.service';
import { WizardStepComponent } from '../../wizard-step/wizard-step.component';

@Component({
  selector: 'submission-step-address',
  templateUrl: './submission-step-address.component.html',
  styleUrls: ['./submission-step-address.component.scss'],
  providers: [{provide: WizardStepComponent, useExisting: forwardRef(() => SubmissionStepAddressComponent)}]
})
export class SubmissionStepAddressComponent extends WizardStepComponent implements OnInit {

  @Input() public formData;
  public form: FormGroup;
  public results = [];
  public states = states;
  public query: string = "";

  // Alias the isValid property so that the parent wizard can check when step is complete
  get isValid() {
    return this.form['valid'];
  }

  constructor(
    private googleSDKProvider: GoogleMapSdkService,
    private connectionProvider: ConnectionService,
    private fb: FormBuilder
  ) { 
    super();
  }

  ngOnInit() {
    // Setup form
    this.form = this.fb.group({
      address: ["", [Validators.required, Validators.maxLength(80)]],
      city: ["", [Validators.required, Validators.maxLength(30)]],
      state: ["", [Validators.required, Validators.maxLength(5)]],
      zipcode: ["", [Validators.required, Validators.maxLength(10), Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)]],
    });

    // Write changes to data model
    this.form.valueChanges.subscribe(form => {
      this.formData.address = form.address;
      this.formData.city = form.city;
      this.formData.state = form.state;
      this.formData.zipcode = form.zipcode;
    });

    // Autocomplete the input with results from Google Places
    if (this.connectionProvider.connected) {
      this.form.controls.address
        .valueChanges
        // .debounceTime(500)
        .subscribe(query => {
          this.query = query;
          this.googleSDKProvider.getPlacePredictions(query).then(results => {
            if (results) {
              this.results = results;
              console.debug('[Wizard step address] Prediction results: ', results);
            } else {
              console.debug('[Wizard step address] No prediction results');
            }
          })
          .catch(err => {
            console.error('[Wizard step address] Error fetching predictions:', err);
            this.results = [];
          });
        });
    } else {
      console.warn('[Wizard step address] Offline: cannot fetch Google Places results');
    }
  }

  // When a result is clicked, fill in the fields automatically
  resultClicked(result) {
    if (this.connectionProvider.connected) {
      this.googleSDKProvider
        .getPlaceDetails(result.place_id)
        .then(result => {
          let address = this.googleSDKProvider.parsePlaceDetailAddress(result);
        
          // Address, city, zip
          let config = [
            ['address', 'address', false],
            ['city', 'city', true],
            ['zipcode', 'zip', true],
            ['state', 'state', true]
          ], to, from, emitEvent;

          for (let i = 0; i < config.length; i++) {
            [to, from, emitEvent] = config[i];
            if (address[from]) {
              this.form.controls[to].setValue(address[from], {
                emitEvent
              });
            }
          }
          
          // Longitude/latitude
          if (result['geometry']) {
            let location = result['geometry']['location'];
            this.formData.point = {
              'longitude': location.lng(),
              'latitude': location.lat()
            }
          }

          this.results = [];
        })
        .catch(err => {
          this.results = [];
        });
    } else {
      console.warn('[Wizard step address] Offline: cannot fetch Google Places details');
    }
  }

}
