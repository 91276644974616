import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ModalSelectModule } from '../modal-select.module';
import { LanguageModalSelectComponent } from './language-modal-select.component';


@NgModule({
  declarations: [
    LanguageModalSelectComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    ModalSelectModule,
    TranslateModule.forChild(),
  ],
  exports: [
    LanguageModalSelectComponent
  ]
})
export class LanguageModalSelectModule {}