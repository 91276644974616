import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from '../../../../pipes/pipes.module';
import { SubmissionStepDetailsComponent } from './submission-step-details.component';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [
    SubmissionStepDetailsComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  exports: [
    SubmissionStepDetailsComponent
  ]
})
export class SubmissionStepDetailsModule {}