import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { PanelModule } from './../../panel/panel.module';
import { ModalModule } from '../../modal/modal.module';
import { WizardModule } from '../wizard/wizard.module';
import { WizardStepModule } from '../wizard-step/wizard-step.module';
import { SubmissionStepUserModule } from './submission-step-user/submission-step-user.module';
import { SubmissionStepCategoryModule } from './submission-step-category/submission-step-category.module';
import { SubmissionStepContactModule } from './submission-step-contact/submission-step-contact.module';
import { SubmissionStepDetailsModule } from './submission-step-details/submission-step-details.module';
import { SubmissionStepAddressModule } from './submission-step-address/submission-step-address.module';
import { SubmissionStepNameModule } from './submission-step-name/submission-step-name.module';
import { SubmissionStepIntroModule } from './submission-step-intro/submission-step-intro.module';
import { SubmissionStepOutroModule } from './submission-step-outro/submission-step-outro.module';
import { SubmissionModalComponent } from './submission-modal.component';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [
    SubmissionModalComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ModalModule,
    PanelModule,
    WizardModule,
    WizardStepModule,
    SubmissionStepIntroModule,
    SubmissionStepNameModule,
    SubmissionStepAddressModule,
    SubmissionStepContactModule,
    SubmissionStepDetailsModule,
    SubmissionStepCategoryModule,
    SubmissionStepUserModule,
    SubmissionStepOutroModule,
    TranslateModule.forChild(),
  ],
  exports: [
    SubmissionModalComponent
  ]
})
export class SubmissionModalModule {}